
import { Factcheck, FactcheckFilter } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import * as FactcheckServices from '@/services/FactCheckService'
import { FactcheckIntegrationPublicationType } from '@/types'
import { ROOT_ACTIONS } from "@/store/actions";
import { format, formatDistance } from 'date-fns'

import { nlBE } from "date-fns/locale";

@Component
export default class EFCSN extends Vue {
    factchecks: Factcheck[] = []
    publishedArticles: Array<{ factcheck: string, external: string }> = []
    async mounted() {
        this.factchecks = await FactcheckServices.GetAllFactChecks({
            partners: ['KNACK', 'VRT', 'FACTCHECK_VLAANDEREN'],
            dateRange: [
                new Date(0),
                new Date()
            ]
        } as FactcheckFilter)

        const published = await FactcheckServices.ListAllPublications();
        this.publishedArticles = published.filter(e => e.integrationType === FactcheckIntegrationPublicationType.GOOGLE_FACTCHECK_TOOLS).map(x => {
            return {
                factcheck: x.FactcheckId,
                external: x.externalId
            }
        })
    }

    dialog = false
    selectedId = ''
    loading = false

    async publishToGoogle(factcheckId?: string) {
        if (factcheckId) {
            this.selectedId = factcheckId
            this.dialog = true
        }
    }

    async submit() {
        try {
            this.loading = true
            const res = await FactcheckServices.CreatePublication(this.selectedId, FactcheckIntegrationPublicationType.GOOGLE_FACTCHECK_TOOLS);
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: 'Factcheck gepubliceerd naar Google Factcheck Tools'
            })
            this.publishedArticles.push({
                factcheck: this.selectedId,
                external: res.externalId
            })
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Er is iets misgegaan'
            })
        } finally {
            this.loading = false
            this.dialog = false
        }
    }

    get list() {
        return this.factchecks.map(x => ({
            id: x.id,
            title: x.title,
            partner: x.Claim?.ClaimReviews[0].Partner?.name,
            sortDate: formatDistance(new Date(x.sortDate), new Date(), {
                addSuffix: true,
                locale: nlBE
            }),
            published: this.publishedArticles.map(e => e.factcheck).includes(x.id!)
        }))
    }

    openExternal(id: string | undefined) {
        const externalId = this.publishedArticles.find(x => x.factcheck === id)?.external
        if (externalId) {
            alert(externalId)
        }
    }
}
