export interface ScrapedArticle {
  id: string,
  title: string,
  description: string,
  date: number,
  url: string,
  thumbnailUrl: string,
  factCheckAuthor: string,
  PartnerId: string,
  fullArticle: string,
  language: string,
  used: boolean,
  createdAt: string;
  claimReviewData: null | any,
  approved: boolean,
  originalIncomingArticle: any
}

export enum FactcheckIntegrationPublicationType {
  GOOGLE_FACTCHECK_TOOLS = 'GOOGLE_FACTCHECK_TOOLS',
  EFCSN = 'EFCSN',
}

export enum FactcheckType {
  FACTCHECK = 'Factcheck',
  DEBUNK = 'Debunk',
  NARRATIVE = 'Narrative',
  PREBUNK = 'Prebunk',
}

export interface Claim {
  id: string;
  text: string,
  source: string,
  claimant: string;
  date: Date | string;
  ClaimCategories: ClaimCategory[];
  ClaimReviews: ClaimReview[],
  createdAt: Date | string;
  platform: string | null;
  mediaformat: string | null;
  associatedMediaType: string | null;
  associatedMedia: string | null;
  politicalParty: string | null;
  contentLocation: string | null;
}

export interface ShortLink {
  id: string,
  name: string,
  FactcheckId: string,
  attributes: Record<string, string>,
  target: string,
}

export interface ClaimReview {
  id?: string;
  publisher: string;
  url: string;
  title: string;
  date: Date | string;
  textualRating: string;
  numericRating: number;
  languageCode: string;
  conclusion: string,
  PartnerId: string | null;
  ClaimId: string | null;
  Claim: Claim | null;
  Partner: Partner | null;
  fullText: string;
  hasVideo: boolean;
  isPriority: boolean;
  countryOfOrigin: string;
  retractImage: boolean;
}


export interface Factcheck {
  id?: string;
  title: string,
  isPublished: boolean,
  description: string,
  Claim: Claim | null;
  type: FactcheckType,
  UserId: string | null;
  ClaimId: string | null;
  sortDate: Date | string;
  claimReviewPublished: boolean;
  FactcheckSeoConfig?: { description: string, keywords: string },
  childFriendly: boolean,
  deletedAt: string | null;
}

export interface ScrapeTask {
  id: string,
  complate: boolean,
  articleCount: number,
  newArticleCount: number,
  Partner: Partner | null;
  createdAt: Date | string;
  updatedAt: Date | string;
}


// end new types

export interface ScrapeTask {
  id: string;
  complete: boolean;
  articleCount: number;
  newArticleCount: number;
  PartnerId: string | null;
  Partner: Partner | null;
}

export interface ClaimCategory {
  id: string,
  nl: string
}

export interface FactcheckFilter {
  query: string,
  dateRange: Array<Date> | null,
  numericRating: number | null,
  category: string | null,
  partners: string[] | null,
}

export interface FactcheckSuggestionReply {
  id: string;
  text: string;
  createdAt: string;
  updatedAt: string;
}

export interface FactcheckSuggestion {
  id: string;
  fullname: string,
  args: string,
  claim: string,
  claimSource: string,
  date: string,
  handled: boolean,
  source: string,
  passed: boolean,
  answered: boolean,
  notes: string,
  email: string,
  createdAt: Date,
  updatedAt: Date,
  FactcheckId: string | null,
  FactcheckSuggestionReplies: FactcheckSuggestionReply[]
}

export interface GoogleClaimReview {
  text: string
  claimant: string,
  claimDate: string,
  claimReview: [
    {
      publisher: {
        name: string,
        site: string
      },
      url: string,
      title: string,
      reviewDate: string,
      textualRating: string,
      languageCode: string
    }
  ]
}

export interface FactcheckGroup {
  id: string,
  name: string,
  factchecks: string[]
}

export interface Partner {
  id: string,
  name: string;
  url: string;
  enabled: boolean;
  imageUrl: string;
  claimReviewsEnabled: boolean;
}

export interface NewsletterSubscriber {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
}

export interface HomeScreenStats {
  visitorsToday: number;
  totalFactcheckCount: number;
  articlesWithoutFactcheckCount: number;
  globalClickTroughRate: number;
  mostPopularIdsToday: null | string[]
}

export interface ApiKey {
  id: string,
  name: string,
  type: string,
  limit: number,
  enabled: boolean,
  allowFullText: boolean,
  updatedAt: string | Date,
  createdAt: string | Date,
  CallCount?: number,
}

export interface ApiKeyCall {
  id: string
  path: string;
  agent: string;
  status: number;
  createdAt: string | Date
}

export enum INTERACTION_TYPE {
  CLICK,
  CLICK_TROUGH,
  FACEBOOK_SHARE,
  INSTAGRAM_SHARE,
  LINK_SHARE,
  TWITTER_SHARE,
  WHATSAPP_SHARE,
  SOCIAL_CLICK,
  FACEBOOK_CLICK_TROUGH,
  TWITTER_CLICK_TROUGH
}

export const INTERACTION_TYPE_ARRAY = [
  'CLICK',
  'CLICK_TROUGH',
  'FACEBOOK_SHARE',
  'INSTAGRAM_SHARE',
  'LINK_SHARE',
  'TWITTER_SHARE',
  'WHATSAPP_SHARE',
  'SOCIAL_CLICK',
  'FACEBOOK_CLICK_TROUGH',
  'TWITTER_CLICK_TROUGH'
]

export interface NewsLetterPublication {
  id?: string;
  title: string;
  url: string;
  createdAt?: string;
  date?: string;
}

export enum AI_INTEGRATION {
  SEO_CONFIG = 'SEO_CONFIG',
  TWEET_SENTIMENT = 'TWEET_SENTIMENT',
}

export interface AiIntegrationConfig {
  id: AI_INTEGRATION,
  model: string,
  temperature: number,
  maxLen: number,
  user: string,
  system: string,
}


export interface Retweet {
  id: string,
  user: string,
  createdAt: string,
}

export interface TweetLike {
  id: string,
  user: string,
  createdAt: string,
}
export interface FullTweet {
  id: string;
  text: string;
  createdAt: string;
  replyToUser: string | null;
  ourTweet: boolean;
  replyTo: string;
  type: string;
  Retweets: Retweet[]
  TweetLikes: TweetLike[],
  sentiment: string
}